import React from "react"
import { graphql } from "gatsby"

import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectPage = ({ data }) => {
    const post = data.markdownRemark
    const textColour = (light, existing = post.frontmatter.colour) => (light ? '#4e4e4e' : existing)
    return (
        <Layout>
            <SEO title={post.frontmatter.title} />
            <section className="project-single-header">
                <div className="container">
                    <div className="project-single--title">
                        <span className="project-category" style={{ color: textColour(post.frontmatter.light) }}>{post.frontmatter.category}</span>
                        <h2 className="project-summary">{post.frontmatter.summary}</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="project-single--quick-info">
                        <h2 style={{ color: textColour(post.frontmatter.light) }}>{post.frontmatter.title}</h2>
                        <ul>
                            <h3>Role</h3>
                            {post.frontmatter.roles.map(role => (
                                <li key={role + `role`}>{role}</li>
                            ))}
                        </ul>
                        <ul>
                            <h3>Delivery Medium</h3>
                            {post.frontmatter.delivery.map(deliver => (
                                <li key={deliver + `deliver`}>{deliver}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="project-single--description">
                        <span className="description--background" style={{ backgroundColor: post.frontmatter.colour }}></span>
                        <div className="description">
                            <p style={{ color: textColour(post.frontmatter.light, '#ffffff') }} >{post.frontmatter.description}</p>
                        </div>
                    </div>
                </div>
            </section>
            { post.frontmatter.opportunity && post.frontmatter.solution ? (
            <section className="brief-elements">
                <div className="container">
                    <div className="opportunity">
                        <h3>Opportunity</h3>
                        <p>{post.frontmatter.opportunity}</p>    
                    </div>
                    <div className="solution">
                        <h3>Solution</h3>
                        <p>{post.frontmatter.solution}</p>
                    </div>
                </div>
            </section>
            ) : (null) }
            <section className="project-single--body">
                <BackgroundImage
                    Tag="span"
                    className="featured-image"
                    fluid={post.frontmatter.featureImage.childImageSharp.fluid}
                    style={{ position: 'relative' }}
                >
                </BackgroundImage>
                <div className="container">
                    <article className="primary-content"  dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        featureImage {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 72) {
                ...GatsbyImageSharpFluid
                }
            }
            publicURL
        }
        colour
        light
        description
        summary
        opportunity
        solution
        roles
        delivery
      }
    }
  }
`

export default ProjectPage